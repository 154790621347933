<template>
  <header>
    <section class="hero">
      <div class="titleDiv z-in-22">
        <h4
          class="hero-text user-select-n font-header"
          id="hero"
          style="word-wrap: break-word"
        >
          <span style="text-transform: capitalize; color: white">{{
            time
          }}</span>
          {{ registeredUser.name }}
        </h4>
      </div>
    </section>
  </header>
</template>

<script>
import { fetchData } from "@/fetch";
export default {
  inject: ["keycloak", "time"],
  name: "homewelkom",
  created() {
    window.addEventListener("scroll", this.scrollFunction);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollFunction);
  },
  mounted() {
    this.registeredUser = fetchData;
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.onLoadHeight.value =
        document.getElementById("hero").offsetHeight + 60;
      document.getElementsByClassName("height110")[0].style.height =
        this.onLoadHeight.value + "px";
    },
    scrollFunction() {
      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0
      ) {
        this.height.value = document.getElementById("hero").offsetHeight + 20;
        document.getElementsByClassName("titleDiv")[0].style.paddingTop =
          "10px";
        document.getElementsByClassName("titleDiv")[0].style.paddingBottom =
          "10px";
        document.getElementsByClassName("height110")[0].style.height =
          this.height.value + "px";
      } else {
        this.height.value = document.getElementById("hero").offsetHeight + 60;
        document.getElementsByClassName("titleDiv")[0].style.paddingTop =
          "30px";
        document.getElementsByClassName("titleDiv")[0].style.paddingBottom =
          "30px";
        document.getElementsByClassName("height110")[0].style.height =
          this.height.value + "px";
      }
    },
  },
  data() {
    return {
      registeredUser: {},
      height: {},
      onLoadHeight: {},
    };
  },
};
</script>

<style>
.margin-top-80 {
  transition: 0.25s;
}
</style>
