<template>
  <section class="card">
    <div class="px-4 py-5 md:px-6 lg:px-8">
      <div class="Aanmeldentitle font-header">
        <h4>Selecteer een locatie:</h4>
      </div>

      <div v-if="registeredUser.registered === true" class="grid">
        <div
          class="col-7 margin-right-3 pt-1 pb-1 mb-2"
          v-for="tab in registeredUser.locations"
          :key="tab.name"
        >
          <div
            class="surface-card card-border p-4 border-round pointer user-select-n"
            @click="tabClicked = tab.id"
            :class="{ active: tabClicked === tab.id }"
          >
            <div class="flex justify-content-between">
              <div>
                <div class="text-900 font-m capitalize">
                  <i class="fa-solid fa-house fa-xs"></i> {{ tab.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider v-if="tabClicked" />
        <div
          style="width: 100%"
          v-for="tab in registeredUser.locations"
          :key="tab.name"
        >
          <div v-show="tabClicked == tab.id">
            <div>
              <OverzichtHome
                @changeWeekIndex="changeWeekIndex"
                v-bind:locationID="tab.id"
                v-bind:locationName="tab.name"
                v-bind:weekIndex="weekIndex"
                v-bind:weekNumber="weekNumber"
                v-bind:isDisabled="isDisabled"
                v-bind:isDisabled1="isDisabled1"
                v-bind:formattedToday="formattedToday"
                v-bind:tabClicked="tabClicked"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import OverzichtHome from "./OverzichtHome.vue";
import { fetchData } from "@/fetch";

export default {
  name: "HouseSelcetion",
  components: {
    OverzichtHome,
  },
  mounted() {
    this.registeredUser = fetchData;
  },
  data() {
    return {
      registeredUser: {},
    };
  },
};
</script>

<script setup>
import { ref } from "vue";

var today = new Date();
var month = today.getMonth() + 1;
var day = today.getDate();
var year = today.getFullYear();
if (day < 10) day = "0" + day;
if (month < 10) month = "0" + month;
var formattedToday = year + "-" + month + "-" + day;

const weekIndex = ref(0);
const tabClicked = ref();
let isDisabled = true;
let isDisabled1 = false;

function changeWeekIndex(data) {
  if (data === "prevWeek" && weekIndex.value > 0) {
    weekIndex.value--;
  }
  if (data === "nextWeek" && weekIndex.value < 2) {
    weekIndex.value++;
  }
  getWeekNumber(weekIndex.value);
  disableButton("prevWeek", weekIndex.value <= 0);
  disableButton("nextWeek", weekIndex.value >= 2);
}

function disableButton(data, bool) {
  if (data === "prevWeek") {
    isDisabled = bool;
  } else if (data === "nextWeek") {
    isDisabled1 = bool;
  }
}

Date.prototype.getWeek = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  var week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

Date.prototype.addWeeks = function (weeks) {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  return new Date(date.setDate(date.getDate() + weeks * 7));
};

var currentDate = new Date();
var weekNumber = currentDate.addWeeks(weekIndex.value).getWeek();
function getWeekNumber(index) {
  weekNumber = currentDate.addWeeks(index).getWeek();
}
</script>

<style scoped>
.active {
  outline: 2px solid var(--ejtblue);
  border: 1px solid transparent;
}

.grid {
  display: flex !important;
}

.parent-week-selector {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .parent-week-selector {
    grid-template-columns: 0fr 2fr 0fr;
  }
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-right: 0.5rem;
}

.toolbar {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-radius: 6px;
  gap: 0.5rem;
}
.toolbar {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3rem;
  padding: 1rem 1rem 1rem 1.5rem;
}

.border-round {
  border-radius: 100px !important;
}

.col-12 {
  padding: 0;
}

@media (min-width: 768px) {
  .margin-right-3 {
    padding-right: 1rem;
  }
}
</style>
