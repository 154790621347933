<template>
  <span
    class="block font-m mb-2"
    :class="{ dateToday: formattedToday === dates.date }"
  >
    Aanmeldingen:</span
  >
  <div class="text-900 font-m capitalize mt-3 mb-3" v-for="users in users">
    <span v-if="users.id !== registeredUser.id">
      <span class="flex align-items-center gap-2 mb-3">
        <div
          class="flex margin-left-0 user-select-n"
          id="navbar2"
          title="Instellingen"
        >
          <Avatar
            class="avatar-border"
            icon="pi pi-user"
            shape="circle"
            style="color: var(--ejtblack)"
          >
            <!-- <img :src="user.profileimage" /> -->
          </Avatar>
        </div>
        <span>
          {{ users.name }}
        </span>
      </span>
      <span class="mb-1 mt-1 ml-5 add-note flex" v-if="users.memo">
        <span class="text-color-gray not-capitalize mr-2">
          <i class="fa-regular fa-comments"></i>
        </span>
        {{ users.memo }}
      </span>
    </span>
  </div>
  <div class="text-900 font-m capitalize" v-for="users in users">
    <span v-if="users.id == registeredUser.id">
      <span
        class="flex align-items-center gap-2 mb-3"
        style="word-wrap: break-word"
      >
        <div
          class="flex margin-left-0 user-select-n"
          id="navbar2"
          title="Instellingen"
        >
          <Avatar
            icon="pi pi-user"
            shape="circle"
            style="color: white; background-color: var(--ejtblue)"
          >
            <!-- <img :src="user.profileimage" /> -->
          </Avatar>
        </div>
        <span v-if="users.id === registeredUser.id" style="width: 85%">
          {{ users.name }} (jij)
        </span>
      </span>
      <span class="mt-1 ml-5 add-note flex">
        <span class="text-color-gray not-capitalize mr-1" v-if="users.memo">
          <i class="fa-regular fa-comments"></i>
        </span>
        <span
          class="text-color-light-gray not-capitalize mr-1"
          v-if="!users.memo"
        >
          <i class="pi pi-plus-circle" style="line-height: 25px"></i>
        </span>
        <Textarea
          :id="`opmerkingen-${locationID}-${index}`"
          v-on:keydown.enter.exact.prevent="
            inputDebounceEnter(dates.date, opmerkingen)
          "
          v-on:input="inputDebounce(dates.date, opmerkingen)"
          class="add-note-input"
          v-model="opmerkingen"
          placeholder="Opmerking toevoegen"
          autoResize
          :spellcheck="false"
          rows="1"
        />
      </span>
    </span>
  </div>
  <div v-if="!dates.registrations[0]" class="text-color-gray font-s">
    Er zijn nog geen aanmeldingen voor deze dag!
  </div>
</template>

<script>
import { useDebounceFn } from "@vueuse/core";
import moment from "moment";
import { fetchData } from "@/fetch";
import { serverURL, baseURL } from "@/fetch";
import keycloak from "@/main";

export default {
  name: "PersonList",
  props: ["users", "dates", "index", "weekday", "locationID", "formattedToday"],
  emits: ["refresh"],
  mounted() {
    this.registeredUser = fetchData;
    this.addOpmerkingen();
  },
  watch: {
    users: function () {
      this.addOpmerkingen();
    },
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    addOpmerkingen() {
      const opmerkingen1 = JSON.parse(JSON.stringify(this.users));
      const opmerkingen2 = opmerkingen1.filter((item) => {
        return item.id === this.registeredUser.id;
      });
      const opmerkingen3 = opmerkingen2[0];
      if (opmerkingen3) {
        if (opmerkingen3.memo !== null) {
          this.opmerkingen = opmerkingen3.memo;
        }
      }
    },
    debouncedFn: useDebounceFn(function (dates, opmerkingen) {
      this.registerForMeal1(dates, opmerkingen);
    }, 1000),
    inputDebounce(dates, opmerkingen) {
      this.debouncedFn(dates, opmerkingen);
    },
    inputDebounceEnter(dates, opmerkingen) {
      document
        .getElementById("opmerkingen-" + this.locationID + "-" + [this.index])
        .blur();
      this.debouncedFn(dates, opmerkingen);
    },
    async registerForMeal1(date, opmerkingen) {
      let self = this;

      const seccondURL = self.locationID;
      await fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/register-for-meal`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            date: date,
            mealType: "DINNER",
            memo: opmerkingen,
          }),
        }
      )
        .then(() => this.$emit("refresh"))
        .then(
          () =>
            document
              .getElementById(
                "opmerkingen-" + this.locationID + "-" + [this.index]
              )
              .blur(),
          this.$toast.add({
            severity: "success",
            summary: "Opgeslagen!",
            detail: "De door jou ingevulde opmerking is opgeslagen!",
            life: 3000,
          })
        );
    },
  },
  data() {
    return {
      registeredUser: {},
      opmerkingen: [],
    };
  },
};
</script>

<style scoped>
.add-note-input {
  border: none;
  width: 100%;
  padding: 0 !important;
  cursor: pointer;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: var(--ejtblue) !important;
}

.p-inputtext::placeholder {
  color: var(--gray-400);
}

.p-inputtext:enabled:focus::placeholder {
  color: transparent;
}

.p-avatar {
  background-color: var(--avatarcolor);
}

.p-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
