<template>
  <Dialog
    v-model:visible="visibleCreateLocation"
    modal
    id="dialog1"
    header="Nieuwe locatie toevoegen"
  >
    <form @submit="onSubmit" class="flex flex-column gap-2">
      <div class="formLocationName">
        <div
          class="p-float-label width101 width100 mt-3"
          @click="changebordercolorlocationname()"
        >
          <InputText id="locationName" v-model="locationName" />
          <label for="locationName">Locatie naam*</label>
        </div>
      </div>
      <InlineMessage severity="error" id="error" style="display: none"
        >Vul alle verplichte velden in!</InlineMessage
      >

      <div class="p-float-label formSubmit">
        <Button @click="createLocation()" label="Gegevens opslaan" />
      </div>
    </form>
  </Dialog>

  <Dialog
    v-model:visible="visibleChangeLocation"
    modal
    id="dialog2"
    header="Locatienaam wijzigen"
  >
    <div class="changeLocationForm">
      <form id="myForm" class="flex flex-column gap-2">
        <div class="margin-all-5 font-m">
          <p class="text-500">Huidige locatie naam:</p>
          <p>{{ selectedHomeName }}</p>
        </div>
        <div class="formLocationName margin-top-15">
          <div
            class="p-float-label width101 width100 font-m"
            @click="changebordercolorlocationname()"
          >
            <InputText id="locationName" v-model="locationName" />
            <label for="locationName ">Nieuwe locatie naam*</label>
          </div>
        </div>
        <InlineMessage
          severity="error"
          id="error"
          style="display: none"
          class="font-m"
          >Vul alle verplichte velden in!</InlineMessage
        >
        <InlineMessage
          severity="error"
          id="errorSame"
          style="display: none"
          class="font-m"
          >Nieuwe locatienaam mag niet hetzelfde zijn als de
          oude!</InlineMessage
        >
        <div class="p-float-label formSubmit">
          <Button
            @click="confirmDeleteLocation(selectedHome)"
            class="deleteButton font-m"
            label="Locatie verwijderen"
          />
          <Button
            @click="changeLocationName(selectedHome, selectedHomeName)"
            class="nameChangeButton font-m"
            label="Wijziging opslaan"
          />
        </div>
      </form>
    </div>
  </Dialog>

  <Dialog
    v-model:visible="visibleAddUser"
    modal
    id="dialogAddUser"
    header="Gebruiker toevoegen"
  >
    <span class="p-text-secondary block mb-5 font-s"
      >Vul het id van de persoon die u wilt toevoegen in.</span
    >
    <div class="flex align-items-center gap-3 mb-3 font-m">
      <label for="User-ID" class="font-semibold w-6rem">Gebruikers-ID</label>
      <InputText
        id="User-ID"
        v-model="addUserID"
        class="flex-auto"
        autocomplete="off"
      />
    </div>
    <div class="flex justify-content-end gap-2">
      <Button
        type="button"
        label="Cancel"
        severity="secondary"
        class="font-m"
        raised
        @click="visibleAddUser = false"
      ></Button>
      <Button
        type="button"
        class="font-m"
        raised
        @click="addUserToLocation(locationID)"
        label="Deelnemer toevoegen"
      />
    </div>
  </Dialog>

  <section>
    <div class="px-4 py-5 md:px-6 lg:px-8">
      <div class="grid">
        <div
          class="col-12 md:col-6 lg:col-3"
          v-for="tab in registeredUser.locations"
          :key="tab.name"
        >
          <div
            class="surface-card card-border p-3 border-round"
            @click="tabClicked = tab.id"
          >
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-m mb-3 user-select-n"
                  >Locatie</span
                >
                <div class="text-900 font-m capitalize">
                  <i class="fa-solid fa-house fa-xs"></i> {{ tab.name }}
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center pointer buttonbgblue border-round"
                style="width: 2rem; height: 2rem"
                v-if="tab.admin === true"
                @click="
                  (visibleChangeLocation = true),
                    (selectedHome = tab.id),
                    (selectedHomeName = tab.name)
                "
              >
                <div class="card flex justify-content-center">
                  <i class="fa-solid fa-ellipsis-vertical fa-xs"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="margin-top-5 border-round">
            <div class="card">
              <div class="surface-card card-border border-round">
                <div class="flex justify-content-between">
                  <div class="card flex w-full md:justify-content-center">
                    <ul
                      class="m-0 p-0 list-none surface-border border-round p-3 flex flex-column w-full md:w-30rem gap-2 font-m"
                    >
                      <PersonOverview
                        v-bind:locationID="tab.id"
                        v-bind:isAdmin="tab.admin"
                        :key="componentKey"
                      />
                      <Divider v-if="tab.admin === true" />
                      <li
                        v-if="tab.admin === true"
                        class="p-2 hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between"
                        @click="(visibleAddUser = true), (locationID = tab.id)"
                        style="cursor: pointer"
                      >
                        <div class="flex align-items-center gap-2">
                          <div
                            class="flex margin-left-0 user-select-n"
                            id="navbar2"
                            title="Instellingen"
                          >
                            <Avatar
                              class="avatar-border"
                              icon="pi pi-user-plus"
                              shape="circle"
                              style="color: var(--ejtblack)"
                            >
                            </Avatar>
                          </div>
                          <span> Deelnemers toevoegen </span>
                        </div>
                      </li>
                      <Divider />
                      <li
                        class="p-2 hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between"
                        @click="
                          confirmLeaveLocation(
                            (id = registeredUser.id),
                            (locationID = tab.id)
                          )
                        "
                        style="cursor: pointer"
                      >
                        <div class="flex align-items-center gap-2">
                          <div
                            class="flex margin-left-0 user-select-n"
                            id="navbar2"
                            title="Instellingen"
                          >
                            <Avatar
                              class="avatar-border"
                              icon="pi pi-sign-out"
                              shape="circle"
                              style="color: var(--ejtblack)"
                            >
                            </Avatar>
                          </div>
                          <span> Locatie verlaten </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <Divider class="show" />
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div
            class="surface-card card-border p-3 border-round pointer"
            @click="visibleCreateLocation = true"
          >
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-m mb-3 user-select-n"
                  >Nieuwe locatie</span
                >
                <div class="text-900 font-m center">
                  <i class="fa-solid fa-house-medical fa-xs"></i> Toevoegen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
function changebordercolorlocationname() {
  document.getElementById("locationName").style.border = "1px solid #ced4da";
}
</script>

<script>
import { ref } from "vue";
import keycloak from "@/main";
import PersonOverview from "./PersonOverview.vue";
import { getOverzichtData, serverURL, baseURL, fetchData } from "@/fetch";

const locationName = ref(null);
const visibleCreateLocation = ref(false);
const visibleChangeLocation = ref(false);
const locationID = ref(null);
const addUserID = ref(null);
const visibleAddUser = ref(false);

export default {
  name: "HomeOverview",
  inject: ["keycloak"],
  components: {
    PersonOverview,
  },
  mounted() {
    this.registeredUser = fetchData;
  },
  methods: {
    refreshData() {
      getOverzichtData();
    },
    confirmDeleteLocation(id) {
      {
        this.$confirm.require({
          message: "Weet u zeker dat u deze locatie wilt verwijderen?",
          header: "Locatie verwijderen?",
          acceptClass: "p-button-danger",
          accept: () => {
            this.deleteLocation(id);
          },
        });
      }
    },
    async deleteLocation(id) {
      const seccondURL = id;
      fetch(`${serverURL}/${baseURL}/${seccondURL}/command/delete-location`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + keycloak.idToken,
        },
      })
        .then(() => this.refreshData())
        .then(() =>
          this.$toast.add({
            severity: "success",
            summary: "Verwijderd!",
            detail: "De gekozen locatie is verwijderd!",
            life: 3000,
          })
        )
        .then(
          () => (visibleChangeLocation.value = false),
          (locationName.value = null)
        );
    },
    changeLocationName(id, locatioName) {
      if (locationName.value == null) {
        document.getElementById("locationName").style.border = "1px solid red";
        document.getElementById("error").style.display = "block";
      } else if (locationName.value == locatioName) {
        document.getElementById("locationName").style.border = "1px solid red";
        document.getElementById("errorSame").style.display = "block";
      } else {
        const seccondURL = id;
        fetch(
          `${serverURL}/${baseURL}/${seccondURL}/command/change-location-name`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + keycloak.idToken,
            },
            body: JSON.stringify({
              name: locationName.value,
            }),
          }
        )
          .then(() => this.refreshData())
          .then(() =>
            this.$toast.add({
              severity: "success",
              summary: "Gewijzigd!",
              detail: "Locatienaam is succesvol gewijzigd!",
              life: 3000,
            })
          )
          .then(
            () => (
              (visibleChangeLocation.value = false), (locationName.value = null)
            )
          );
      }
    },
    createLocation() {
      if (locationName.value == null) {
        document.getElementById("locationName").style.border = "1px solid red";
        document.getElementById("error").style.display = "block";
      } else {
        fetch(`${serverURL}/${baseURL}/command/create-location`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({ name: locationName.value }),
        })
          .then(() => this.refreshData())
          .then(() =>
            this.$toast.add({
              severity: "success",
              summary: "Aangemaakt!",
              detail: "Nieuwe locatie is aangemaakt!",
              life: 3000,
            })
          )
          .then(
            () => (visibleCreateLocation.value = false),
            (locationName.value = null)
          );
      }
    },
    confirmLeaveLocation(id, locationID) {
      {
        this.$confirm.require({
          message: "Weet u zeker dat u deze locatie wilt verlaten?",
          header: "Locatie verlaten?",
          acceptClass: "p-button-danger",
          accept: () => {
            this.leaveLocation(id, locationID);
          },
        });
      }
    },
    async leaveLocation(id, locationID) {
      const seccondURL = locationID;
      fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/user-leave-location`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            userId: id,
          }),
        }
      )
        .then(() => this.refreshData())
        .then(() =>
          this.$toast.add({
            severity: "success",
            summary: "Verlaten!",
            detail: "U hebt de gekozen locatie verlaten!",
            life: 3000,
          })
        );
    },
    async addUserToLocation(locationID) {
      self = this;
      const seccondURL = locationID;
      await fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/add-user-to-location`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            userId: addUserID.value,
          }),
        }
      ).then(
        function (response) {
          if (response.ok === true) {
            this.$toast.add({
              severity: "success",
              summary: "Toegevoegd!",
              detail: "De gekozen persoon is toegevoegd!",
              life: 3000,
            });
            this.componentKey += 1;
            visibleAddUser.value = false;
            addUserID.value = null;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Er is een fout opgetreden.",
              detail: "",
              life: 3000,
            });
          }
        }.bind(this)
      );
    },
  },
  data() {
    return {
      registeredUser: {},
      visibleAddUser,
      addUserID,
      locationID,
      componentKey: 0,
    };
  },
};
</script>

<style scoped>
.p-divider.p-divider-horizontal {
  margin: 0.5rem 0;
  padding: 0 1.25rem;
}
</style>
