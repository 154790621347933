<template>
  <li
    v-for="(user, index) in locatieOverzicht.members"
    :key="user.id"
    class="p-2 border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between"
  >
    <div
      class="flex align-items-center gap-2"
      style="word-wrap: break-word; width: 65%"
    >
      <div
        class="flex margin-left-0 user-select-n"
        id="navbar2"
        title="Instellingen"
      >
        <Avatar
          class="avatar-border"
          icon="pi pi-user"
          shape="circle"
          style="color: var(--ejtblack)"
        >
          <!-- <img :src="user.profileimage" /> -->
        </Avatar>
      </div>
      <span v-if="user.id === registeredUser.id" style="width: 80%">
        {{ user.name }} (jij)
      </span>
      <span v-else style="width: 85%">{{ user.name }}</span>
    </div>
    <div class="flex align-items-center gap-2">
      <div class="flex margin-left-0 user-select-n">
        <Tag
          v-if="user.admin"
          value="Beheerder"
          rounded
          severity="warning"
          class="font-s"
        />
        <Tag
          v-if="!user.admin"
          value="Member"
          rounded
          severity="Primary"
          class="font-s"
        />
      </div>
      <!-- <div>
        <Button
          icon="fa-solid fa-ellipsis-vertical fa-xs"
          severity="secondary"
          class="buttonbgblue"
          text
          outlined
          rounded
          v-if="isAdmin"
          style="height: 32px; width: 32px; color: var(--ejtblack)"
        />
      </div> -->
    </div>
  </li>
</template>

<script>
import { getLocatieOverzichtdata, fetchData } from "@/fetch";
import { ref } from "vue";

export default {
  name: "PersonOverview",
  props: ["locationID", "isAdmin"],
  inject: ["keycloak"],
  mounted() {
    this.loadLocationData();
  },
  methods: {
    async loadLocationData() {
      const LocatieData = ref(await getLocatieOverzichtdata(this.locationID));
      this.locatieOverzicht = LocatieData;
      this.registeredUser = fetchData;
    },
  },
  data() {
    return {
      registeredUser: {},
      locatieOverzicht: {},
    };
  },
};
</script>
