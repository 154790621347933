import keycloak from "./main";
import { ref } from "vue";

const serverURL = "https://server.eetjethuis.nl";
const websiteURL = "https://eetjethuis.nl/app/";
// const websiteURL = "http://localhost:8080/app/";
const baseURL = "server/locations";
const fetchData = ref({});
const fetchLocatieData = ref({});

const getOverzichtData = () => {
  fetch(`${serverURL}/server/users/me`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.idToken,
    },
  })
    .then((response) => response.json())
    .then((data) => (fetchData.value = data));
  return fetchData;
};

const getLocatieOverzichtdata = (locationID) =>
  fetch(`${serverURL}/${baseURL}/${locationID}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak.idToken,
    },
  }).then((response) => response.json());

export {
  getOverzichtData,
  getLocatieOverzichtdata,
  fetchData,
  fetchLocatieData,
  serverURL,
  baseURL,
  websiteURL,
};
