<template>
  <div class="newUserForm">
    <div class="surface-card p-4 card-border border-round">
      <registerNewPersonForm />
    </div>
  </div>
</template>

<script>
import registerNewPersonForm from "./RegisterNewPersonForm.vue";
export default {
  name: "registerNewPerson",
  components: {
    registerNewPersonForm,
  },
};
</script>
